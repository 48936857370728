#about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
}

#diagram {
    width: 100%;
}

.about-paragraph {
    max-width: 800px;
    text-align:justify;
    padding-bottom: 15px;
}

dl {
    text-align: left;
}

dt {
    padding-bottom: 10px;
    text-decoration: underline;
}

dd {
    display: list-item;
}


a {
    text-decoration: none !important;
}

table {
    text-align: center;
    max-width: 600px !important;
}