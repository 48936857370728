#imagedisp{
    overflow-y: auto;
    text-align: center;
    display: flex;
    justify-content: left;
}

#mainimg{
    max-width: 560px;
    max-height: 600px;
    width: 100%;
}

#wrapper-inner{
    width: 100%;
    height: fit-content;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    text-align: left;
}

#original{
    width: 70%;
}

#pred{
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    display: block;
    margin-bottom: 20px;
    padding-right: 2%;
    padding-left: 2%;
    border: 1px solid lightgray;
    font-family: monospace;
    max-height: 200px;
    overflow-y: scroll;
}


#ketcher{
    clear: both;
    margin-top: 10px;
    border: 1px solid lightgray;
}

select{
    max-width: 40%;
    word-wrap: break-word;
}

h5 {
    margin-top: 10px !important;
    text-align: left;
}

#resultButtons{
    text-align: right;
  }
  
#resultBody{
overflow-y: hidden;
overflow-x: hidden;
}

.buttongroupwrap {
    overflow-x: auto;
    margin-top: 3px;
}

.btn-ketcher {
    margin: 0 5px;
}


table {
    text-align: left;
    max-width: 50%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    table-layout: fixed;
    margin-bottom: 0 !important;
    height: fit-content;
    border: 1px solid lightgray;
}

#leftTableCol {
    font-weight: bold;
    width: 20%;
}

#tableElement {
    max-height: 120px;
    word-wrap: break-word;
    display: block;
    overflow-y: auto;
}