
.display-linebreak {
  white-space: pre-line;
  overflow-wrap: break-word;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin: 0px 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

form{
  margin-right: 0px;
  margin-bottom: -20px;
}

.viewer{
  width: 560px;
  min-height: 500px;
  max-height: 700px;
  background-color: #e4e4e4;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
}

span{
  margin-top: 3px;
}

.form-control{
  width: 480px !important;
  display: inline !important;
}

#spacer{
  width: 0px;
}

.justifyleft{
  display: flex;
  justify-content: left;
   align-items: center;
}

#results{
  width: 650px;
  overflow-y: auto;
  overflow-x: hidden;
  overflow-wrap: break-word;
}

#molresults{
  margin-top: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  overflow-wrap: break-word;
}

#resultButtons{
  text-align: right;
}

#resultBody{
  overflow-y: auto;
  overflow-x: hidden;
}

select{
  display: inline !important;
  margin-left: 10px !important;
  width: 170px !important;
}